import React from 'react';
import { lazy, Suspense, useRef, useState, useEffect } from 'react';
import FormatCaption from './components/formatCaption/formatCaption';
import { Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import Interactive from './components/Interactive/Interactive';

const BodyPostYoutube = lazy(() => import('./components/BodyPostYoutube/BodyPostYoutube'));
const BodyPostFacebook = lazy(() => import('./components/BodyPostFacebook/BodyPostFacebook'));
const BodyPostTwitch = lazy(() => import('./components/BodyPostTwitch/BodyPostTwitch'));
const BodyPostTiktok = lazy(() => import('./components/BodyPostTiktok/BodyPostTiktok'));
const BodyPostInstagram = lazy(() => import('./components/BodyPostInstagram/BodyPostInstagram'));
const BodyPostTwitter = lazy(() => import('./components/BodyPostTwitter/BodyPostTwitter'));

const Post = ({
    hide,
    typepost = '',
    url,
    postid = '',
    post,
    caption,
    deletePost,
    showDelToLib,
    setPostContinue,
    setShowPostContinue,
    updatePost,
    iconBidding,
}) => {
    // const caption = useRef('');
    return (
        <div className="gird-item">
            <div className="profile__post__item" data-postid={postid}>
                <div className="profile__post__heading">
                    <div className="profile__post__avatar">
                        <img
                            src="/assets/dak.jpg"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            alt="Avatar"
                        />
                    </div>
                    <div className="profile__post__info">
                        <div className="profile__post__infoheading">
                            <h2>Popular</h2>
                            <div className="profile__post__star">
                                <FaStar className="global__star" />
                            </div>
                        </div>

                        {/* <p
                            onMouseEnter={(e) => {
                                setStyleTime({ display: 'block' });
                            }}
                            onMouseLeave={(e) => {
                                setStyleTime({ display: 'none' });
                            }}
                        >
                            {content.post}
                        </p>
                        <span className={Styles.time__post} style={hoverTimes}>
                            {mapTime(post.post.created_at)}
                        </span> */}
                    </div>
                </div>
                <div className="profile__post__main">
                    <div className="profile__post__content">
                        {/* {
              typepost == "normal" && <p className={Styles.profile__post__content__captions}>fsdf fsf  345  dggdfg dg fdsg</p>
            } */}

                        {caption !== null && caption !== undefined ? (
                            <a href={url} className="profile__post__content__captions" target="_blank">
                                {caption}
                            </a>
                        ) : (
                            <p className="profile__post__content__captions"></p>
                        )}

                        {typepost == 'youtube' && (
                            <>
                                <Suspense fallback={<div></div>}>
                                    <BodyPostYoutube url={url} />
                                </Suspense>
                            </>
                        )}
                        {/* <>
                            <Suspense fallback={<div></div>}>
                                <BodyPostYoutube url={url} />
                            </Suspense>
                        </> */}
                        {typepost == 'facebook' && (
                            <>
                                <Suspense fallback={<div></div>}>
                                    <BodyPostFacebook dataHref={url} className={'fb-post'} />
                                </Suspense>
                            </>
                        )}
                        {typepost == 'tiktok' && (
                            <>
                                <Suspense fallback={<div></div>}>
                                    <BodyPostTiktok url={url} />
                                </Suspense>
                            </>
                        )}
                        {typepost == 'instagram' &&
                            (console.log(url),
                            (
                                <>
                                    <Suspense fallback={<div></div>}>
                                        <BodyPostInstagram url={url} />
                                    </Suspense>
                                </>
                            ))}
                        {typepost == 'twitter' && (
                            <>
                                <Suspense fallback={<div></div>}>
                                    <BodyPostTwitter url={url} />
                                </Suspense>
                            </>
                        )}
                    </div>
                    <Interactive />
                </div>
            </div>
        </div>
    );
};

export default Post;
