// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0; }

body {
  background-color: #dbdbdb; }

.wrap-loader {
  background-color: #909090;
  opacity: 0.6;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999; }

.loader {
  z-index: 999;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3835eb;
  width: 60px;
  height: 60px;
  /* Safari */
  animation: spin 2s linear infinite;
  position: relative;
  left: calc(50% - 30px);
  top: 50%;
  transform: translate(-50%);
  z-index: 999; }

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.error {
  position: fixed;
  color: red;
  left: 50%;
  top: 50%;
  transform: translate(-50%); }
`, "",{"version":3,"sources":["webpack://./src/styles/globals.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB,EAAA;;AAE1B;;EAEI,SAAS;EACT,UAAU,EAAA;;AAEd;EAEI,yBAAyB,EAAA;;AAG7B;EACI,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,WAAW;EACX,MAAM;EACN,YAAY,EAAA;;AAGhB;EACI,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACgC,WAAA;EAC5C,kCAAkC;EAClC,kBAAkB;EAClB,sBAAsB;EACtB,QAAQ;EACR,0BAA0B;EAC1B,YAAY,EAAA;;AAGhB,WAAA;;AAUA;EACI;IACI,uBAAuB,EAAA;EAE3B;IACI,yBAAyB,EAAA,EAAA;;AAGjC;EACI,eAAe;EACf,UAAU;EACV,SAAS;EACT,QAAQ;EACR,0BAA0B,EAAA","sourcesContent":["* {\n    box-sizing: border-box;\n}\nhtml,\nbody {\n    margin: 0;\n    padding: 0;\n}\nbody {\n    // background-color: #424242;\n    background-color: #dbdbdb;\n}\n\n.wrap-loader {\n    background-color: #909090;\n    opacity: 0.6;\n    height: 100%;\n    position: fixed;\n    width: 100%;\n    top: 0;\n    z-index: 999;\n}\n\n.loader {\n    z-index: 999;\n    border: 8px solid #f3f3f3;\n    border-radius: 50%;\n    border-top: 8px solid #3835eb;\n    width: 60px;\n    height: 60px;\n    -webkit-animation: spin 2s linear infinite; /* Safari */\n    animation: spin 2s linear infinite;\n    position: relative;\n    left: calc(50% - 30px);\n    top: 50%;\n    transform: translate(-50%);\n    z-index: 999;\n}\n\n/* Safari */\n@-webkit-keyframes spin {\n    0% {\n        -webkit-transform: rotate(0deg);\n    }\n    100% {\n        -webkit-transform: rotate(360deg);\n    }\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n.error {\n    position: fixed;\n    color: red;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
