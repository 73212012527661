import {
    AiOutlineHeart, // Tim
    AiFillMessage, // Chat
    AiOutlineShareAlt, // Chia sẽ
    AiOutlineExclamationCircle,
} from 'react-icons/ai';

import {
    FaRegHeart, // Tim
    FaHeart,
    FaRegCommentDots,
} from 'react-icons/fa';

export default function Interactive() {
    return (
        <>
            {/* <div className="reaction__title">
                <label className="reaction__like">0</label>
                <label className="reaction__vote">0</label>
                <label className="reaction__comment">0</label>
                <label className="reaction__share">0</label>
            </div> */}
        </>
    );
}
