import './styles/App.scss';
import Post from './Post';
import { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Masonry from 'react-masonry-css';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

function App() {
    const [search, setSearch] = useState(`""`);
    const [post, setPost] = useState([]);
    const [loader, setLoader] = useState(false);
    const [er, setError] = useState('');
    const [socialType, setSocialType] = useState([]);
    const [pageActive, setPageActive] = useState(1);
    const [pageNumber, setPageNumber] = useState([]);
    const [postPagination, setPostPagination] = useState([]);

    var axios = require('axios');

    function handleGetPost(posts) {

        var config = {
            method: 'get',
            url: `https://search.irics.vn/api/bot-search?keyword=${search}&limit=20`,
            headers: {},
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    setLoader(false);
                    console.log(response.data.data);
                    // var arrPost = [];
                    // arrPost.push(...response.data.data[0].data);
                    // arrPost.push(...response.data.data[1].data);
                    // arrPost.push(...response.data.data[2].data);
                    // arrPost.push(...response.data.data[3].data);
                    setPost(response.data.data);

                    // console.log(arrPost);
                    //xử lí số lượng trang
                    var lsPage = [];
                    var count = Math.ceil(response.data.data.length / 50);
                    for (let i = 0; i < count; i++) {
                        lsPage.push(i + 1);
                    }
                    setPageNumber(lsPage);

                    PaginationChange(pageActive, response.data.data);
                }
            })
            .catch(function (error) {
                setPost('');
                setError('Lỗi' + error);
                setLoader(false);
            });

        // var requestOptions = {
        //     method: 'GET',
        //     redirect: 'follow'
        //   };
          
        //   fetch("https://search.irics.vn/api/bot-search?keyword=demo&limit=20", requestOptions)
        //     .then(response => response.text())
        //     .then(result => console.log(result))
        //     .catch(error => console.log('error', error))
  
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setLoader(true);
            setError('');
            handleGetPost('s');
        }
    };
    function handleClickPaginationPrev() {
        if (pageActive > 1) {
            setPageActive(pageActive - 1);
            PaginationChange(pageActive - 1, post);
            scrollToTop();
        }
    }
    function PaginationChange(pageActive, dataPost) {
        scrollToTop();
        //thêm type social
        // var data = dataPost;
        var arrAllPost = dataPost;
        // for (let i = 0; i < data.length; i++) {
        //     for (let j = 0; j < data[i].data.length; j++) {
        //         const element = data[i].data[j];
        //         element.social = data[i].social;
        //     }
        //     arrAllPost.push(...data[i].data);
        // }
        //phân trang
        var listPagination = [];
        let start = 50 * pageActive - 50;
        let end;
        if (pageActive * 50 > arrAllPost.length) {
            end = arrAllPost.length;
        } else {
            end = start + 50;
        }
        for (let i = start; i < end; i++) {
            listPagination.push(arrAllPost[i]);
        }
        // console.log(data);
        setPostPagination(listPagination);
    }
    // console.log(postPagination);
    function handleClickPaginationNext() {
        if (pageActive < pageNumber.length) {
            setPageActive(pageActive + 1);
            PaginationChange(pageActive + 1, post);
            scrollToTop();
        }
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    console.log(postPagination);
    return (
        <>  
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className='grid-container'>
                    <div style={{display: 'flex', justifyContent: 'center', marginRight: '-20px'}}>
                        <img src="/assets/irics-logo.png" className='logo' alt="irics logo" />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src="/assets/asp-logo.png" className='logo logoAsp' alt="asp logo" />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginLeft: '-20px'}}>
                        <img src="/assets/stech-logo.png" className='logo' alt="stech logo" />
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', margin: '0 20px'}}>
                <h2 className='title'>PHẦN MỀM LỌC THÔNG TIN XẤU ĐỘC TRÊN MẠNG XÃ HỘI</h2>
            </div>
            <div className="App">
                <div className="search-form">
                    <input
                        type="text"
                        className="search"
                        placeholder="Tìm kiếm..."
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                {loader && (
                    <div className="wrap-loader">
                        <div className="loader"></div>
                    </div>
                )}
                {er != null ? '' : <h3 className="error">{er}</h3>}
                <div className="search-result grid">
                    {postPagination.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
                            Trang {pageActive}
                        </div>
                    )}
                    <Masonry breakpointCols={2} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                        {postPagination.length > 1
                            ? postPagination.map((item) => (
                                <Post caption={item?.caption} url={item?.link} typepost={item?.social_type} />
                            ))
                            : ''}
                    </Masonry>
                </div>
                <div>
                    {pageNumber.length == 0 ? (
                        ''
                    ) : (
                        <Pagination style={{ display: 'flex', justifyContent: 'center' }}>
                            <PaginationItem disabled={pageActive == 1 ? true : false} onClick={handleClickPaginationPrev}>
                                <PaginationLink previous />
                            </PaginationItem>
                            {pageNumber.map((pageNumber, index) => (
                                <PaginationItem
                                    key={index}
                                    onClick={() => {
                                        setPageActive(pageNumber);
                                        PaginationChange(pageNumber, post);
                                    }}
                                    active={pageActive == pageNumber ? true : false}
                                >
                                    <PaginationLink>{pageNumber}</PaginationLink>
                                </PaginationItem>
                            ))}
                            <PaginationItem disabled={pageActive == pageNumber.length ? true : false}>
                                <PaginationLink next onClick={handleClickPaginationNext} />
                            </PaginationItem>
                        </Pagination>
                    )}
                </div>
                <>
                    {/* <iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0PqAr1ZqdQowJLoMQEs7KYkLvtrcqWNSSRHJUHKfFcWNaousEvZo7ugt1u74cH7wCl%26id%3D100029664848699&show_text=true&width=500" width="500" height="158" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe> */}
                    {/* <Iframe
                            url="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fm.facebook.com%2Fgroups%2FgocnhocuaFreelanceContentWriter%2Fpermalink%2F1504052360042835&show_text=true"
                            width="100%"
                            height="100%"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                        /> */}
                </>
            </div>
        </>
    );
}

export default App;
