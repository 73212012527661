import { Link } from 'react-router-dom';

const FormatCaption = (caption) => {
    const newCaptionArr = [];
    if (caption != '' && caption != undefined) {
        let itemCaption = caption.split(' ');
        let newCaption = '';
        itemCaption.map((item, index) => {
            if (checkHashTag(item)) {
                newCaptionArr.push(
                    <Link
                        to={{
                            pathname: '/searched/hastag',
                            query: {
                                keyword: item,
                            },
                        }}
                    >
                        <span style={{ color: '#f9d205', cursor: 'pointer', width: '10%' }}>
                            {' '}
                            {item.substr(0, 60)}{' '}
                        </span>
                    </Link>,
                    ' ',
                );
                // newCaption = newCaption + `<span style="color: #f9d205; cursor: pointer;"> ${item} </span>` + " ";
            } else {
                newCaptionArr.push(item, ' ');
                // newCaption = newCaption + item + " ";
            }
        });
        var arrCaption = [];
        newCaptionArr.map((caption) => {
            if (typeof caption === 'string') {
                const checkCaption = caption.indexOf('/n');
                if (checkCaption >= 0) {
                    var newCaptions = [];
                    for (let index = 0; index < caption.length; index++) {
                        const element = caption[index];
                        if (caption[index] != '/') {
                            if (caption[index] != 'n') {
                                newCaptions.push(caption[index]);
                            }
                        } else if (caption[index] == '/' && caption[index + 1] == 'n') {
                            newCaptions.push(<br />);
                        }
                    }
                    console.log(newCaptions);
                    arrCaption.push(newCaptions);
                } else {
                    arrCaption.push(caption, '');
                }
            } else {
                arrCaption.push(caption, '');
            }
        });
        return arrCaption;
    } else {
        return '';
    }
};

const checkHashTag = (caption) => {
    if (caption != '') {
        if (caption[0] == '#') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export default FormatCaption;
